import {Exclude, Expose, Type} from 'class-transformer';
import {IohAdminUnitConcept} from '../admin-unit-concept/ioh-admin-unit-concept';
import {IohGender} from '../concept/ioh-gender';
import {IohAdminMethodConcept} from '../admin-method-concept/ioh-admin-method-concept';

export class IohCaresSiteMeasurementConceptInfo {
  @Expose({name: 'measurement_site_id'})
  measurementSiteId: number;

  @Expose({name: 'gender_concept_id'})
  genderConceptId: number;

  @Expose({name: 'unit_concept_id'})
  unitConceptId: number;

  @Expose({name: 'method_concept_id'})
  methodConceptId: number;

  @Expose({name: 'range_low'})
  rangeLow?: number;

  @Expose({name: 'range_high'})
  rangeHigh: number;

  @Expose({name: 'measurement_minute'})
  measurementMinute: number;

  @Expose({name: 'default_value'})
  defaultValue: string;

  @Expose({name: 'unit_concept'})
  @Type(() => IohAdminUnitConcept)
  @Exclude({ toPlainOnly: true })
  unitConcept?: IohAdminUnitConcept;

  @Expose({name: 'gender_concept'})
  @Type(() => IohGender)
  @Exclude({ toPlainOnly: true })
  genderConcept?: IohGender;

  @Expose({name: 'method_concept'})
  @Type(() => IohAdminMethodConcept)
  @Exclude({ toPlainOnly: true })
  methodConcept?: IohAdminMethodConcept;
}
