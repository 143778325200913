import { deserialize, Expose, serialize, classToPlain } from 'class-transformer';
import { UiModel } from '../_ui-helper/ui-model';

export class IohProvider extends UiModel{
  @Expose({name: 'first_name'})
  firstName: string;

  @Expose({name: 'last_name'})
  lastName: string;

  @Expose({name: 'username'})
  username: string;

  @Expose({name: 'password'})
  password: string;

  @Expose({name: 'phone_number'})
  phoneNumber: string;

  @Expose({name: 'email'})
  email: string;

  @Expose({name: 'id_no'})
  idNo: string;

  @Expose({name: 'year_of_birth'})
  yearOfBirth: string;

  @Expose({name: 'npi'})
  npi: string;

  @Expose({name: 'dea'})
  dea: string;

  @Expose({name: 'specialty_concept_id'})
  specialtyConceptId: string;

  @Expose({name: 'gender_concept_id'})
  genderConceptId: string;

  @Expose({name: 'provider_role_concept_id'})
  providerRoleConceptId: string;

  @Expose({name: 'provider_name'})
  providerName?: string;

  @Expose({name: 'provider_id'})
  providerId?: number;

  @Expose({name: 'care_site_id'})
  careSiteId: number;

  static fromJson(providerDto: any): IohProvider {
    return deserialize(IohProvider, providerDto);
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
