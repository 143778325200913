import {Injectable} from '@angular/core';
import {ApiPath} from '../../constance/api-path';
import {ApiService} from '../_core/api.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {IohCareSiteMeasurementPackageConcept} from '../../models/care-site-measurement-package-concept/ioh-care-site-measurement-package-concept';
import {IohChildrenCareSiteMeasurementConcept} from '../../models/care-site-measurement-package-concept/ioh-children-care-site-measurement-concept';

const MeasurementPackageConcept = ApiPath.measurementPackageConcept;

@Injectable({
  providedIn: 'root'
})
export class CareSiteMeasurementPackageConceptService {

  constructor(
    private apiService: ApiService
  ) { }

  getMeasurementPackageConcept(careSiteId: number): Observable<IohCareSiteMeasurementPackageConcept[]> {
    const url = `${environment.uCareUrl}/${MeasurementPackageConcept.measurementPackageConcept(careSiteId)}`;
    return this.apiService.get(url)
      .pipe(
        map((response: HttpResponse<any>) => {
          const body = response.body || [];
          console.log(body);
          return body.map(item => IohCareSiteMeasurementPackageConcept.fromJson(JSON.stringify(item)));
        })
      );
  }

  createMeasurementPackageConcept(careSiteId: number,
                                  measurementConcept: any
  ): Observable<IohCareSiteMeasurementPackageConcept> {
    const url = `${environment.uCareUrl}/${MeasurementPackageConcept.measurementPackageConcept(careSiteId)}`;
    return this.apiService.post(url, measurementConcept)
      .pipe(
        map((response: HttpResponse<any>) => {
          const body = response.body;
          return IohCareSiteMeasurementPackageConcept.fromJson(JSON.stringify(body));
        })
      );
  }

  addMeasurementConceptIntoPackage(careSiteId: number,
                                   measurementPackageConceptId: number,
                                   measurementConcepts: IohChildrenCareSiteMeasurementConcept[]
  ): Observable<IohCareSiteMeasurementPackageConcept> {
    const url = `${environment.uCareUrl}/${MeasurementPackageConcept.addMeasurementConceptIntoPackage(careSiteId, measurementPackageConceptId)}`;
    return this.apiService.post(url, measurementConcepts)
      .pipe(
        map((response: HttpResponse<any>) => {
          const body = response.body;
          return IohCareSiteMeasurementPackageConcept.fromJson(JSON.stringify(body));
        })
      );
  }

  updateMeasurementPackageConceptPrice(careSiteId: number,
                                       measurementPriceId: number,
                                       price: IohChildrenCareSiteMeasurementConcept
  ): Observable<IohCareSiteMeasurementPackageConcept> {
    const url = `${environment.uCareUrl}/${MeasurementPackageConcept.updateMeasurementPackageConceptPrice(careSiteId, measurementPriceId)}`;
    return this.apiService.put(url, price)
      .pipe(
        map((response: HttpResponse<any>) => {
          const body = response.body;
          return IohCareSiteMeasurementPackageConcept.fromJson(JSON.stringify(body));
        })
      );
  }
}
