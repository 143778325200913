import {classToPlain, deserialize, Expose, serialize, Type} from 'class-transformer';

export class IohIcd {
  @Expose({name: 'concept_id'})
  conceptId?: string;

  @Expose({name: 'concept_code'})
  conceptCode: string;

  @Expose({name: 'concept_name'})
  conceptName: string;

  @Expose({name: 'omop_concept_code'})
  omopConceptCode: string;

  @Expose({name: 'care_site_id'})
  careSiteId?: number;

  @Expose({name: 'omop_concept_id'})
  omopConceptId: string;

  @Expose({name: 'domain_id'})
  domainId: string;

  @Expose({name: 'vocabulary_id'})
  vocabularyId: string;

  @Expose({name: 'descendants'})
  @Type(() => IohIcd)
  descendants: IohIcd[];



  static fromJson(role: any): IohIcd {
    return deserialize(IohIcd, role);
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
