export const DbInfo = {
  WRITE_MODE: {
    READ_WRITE: 'readwrite'
  },
  DB_NAME: {
    CONCEPT: 'concept-db'
  },
  DB_VERSION: 1,
  STORES: {
    GEOGRAPHY_PROVINCE: 'geography-province',
    GEOGRAPHY_DISTRICT: 'geography-district',
    GEOGRAPHY_WARD: 'geography-ward',
    CONCEPT_GENDER: 'concept-gender',
    CONCEPT_ROLE: 'concept-role',
    CONCEPT_PROVIDER_ROLE: 'concept-provider-role',
    CONCEPT_PAYMENT_OBJECT: 'concept-payment-object',
    CONCEPT_ICD_10: 'concept-icd-10',
    CONCEPT_ICD_9: 'concept-icd-9',
    CONCEPT_ICD_8: 'concept-icd8',
    ADMIN_METHOD_CONCEPT: 'admin-method-concept',
    ADMIN_UNIT_CONCEPT: 'admin-unit-concept',
    ADMIN_MEASUREMENT_CONCEPT: 'admin-measurement-concept',
    CARE_SITE_MEASUREMENT_CONCEPT: 'care-site-measurement-concept',
    CARE_SITE_MEASUREMENT_PACKAGE_CONCEPT: 'care-site-measurement-package-concept',
  },
};
