import {IohProvince} from './ioh-province';
import {classToPlain, deserialize, Expose, serialize, Type} from 'class-transformer';

export class IohGeography{
  @Type(() => IohProvince)
  list: IohProvince[];

  @Expose({name: 'total'})
  total: number;

  static fromJson(geographyDto: any): IohGeography {
    return deserialize(IohGeography, geographyDto);
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
