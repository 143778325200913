import {classToPlain, deserialize, Exclude, Expose, Type, serialize} from 'class-transformer';
import {IohWardDescendant} from '../descendant/ioh-ward-descendant';
import {UiModel} from '../_ui-helper/ui-model';

export class IohCareSite extends UiModel{
  @Expose({name: 'care_site_id'})
  careSiteId: number;

  @Expose({name: 'care_site_name'})
  careSiteName: string;

  @Expose({name: 'parent_care_site_id'})
  parentCareSiteId?: number;

  @Expose({name: 'ward_id'})
  wardId?: number;

  @Expose({name: 'address'})
  address?: string;

  @Expose({name: 'ward'})
  @Exclude({ toPlainOnly: true })
  @Type(() => IohWardDescendant)
  ward?: IohWardDescendant;

  @Expose({name: 'descendants'})
  @Exclude({ toPlainOnly: true })
  @Type(() => IohCareSite)
  descendants?: IohCareSite[];

  @Expose({name: 'created_at'})
  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  createdAt?: Date;

  @Expose({name: 'updated_at'})
  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  updatedAt?: Date;

  static fromJson(careSiteDto: any): IohCareSite {
    const careSite = deserialize(IohCareSite, careSiteDto);

    if (Array.isArray(careSite.descendants)) {
      careSite.descendants = careSite.descendants.map(desCareSite => IohCareSite.fromJson(desCareSite));
    }

    return careSite;
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
