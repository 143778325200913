import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, finalize, map, switchMap, tap} from 'rxjs/operators';
import {UserModel} from '..';
import {AuthModel} from '../_models/auth.model';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {AccountService} from '../../../ucare/services/account/account.service';
import {OpenIdService} from './open-id.service';
import {KeycloakTokenInfo} from '../../../ucare/models/account/keycloak-token-info';
import {StorageService} from '../../../ucare/services/storage/storage.service';
import * as Keycloak from 'keycloak-js';
import jwt_decode from 'jwt-decode';
import {KeycloakTokenDecodeInfo} from '../../../ucare/models/account/keycloak-token-decode-info';
import {fromPromise} from 'rxjs/internal-compatibility';
import {IohUser} from '../../../ucare/models/user/ioh-user';
import {UserService} from '../../../ucare/services/user/user.service';
import {ConceptsDbService} from '../../../ucare/services/_db/concepts-db.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [];
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;


  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private openIdService: OpenIdService,
    private storageService: StorageService,
    private accountService: AccountService,
    private userService: UserService,
    private conceptsDbService: ConceptsDbService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  // login(email: string, password: string): Observable<any> {
  //   this.isLoadingSubject.next(true);
  //   return this.accountService.login(email, password).pipe(
  //     tap((auth: any) => this.setAuthFromLocalStorage(auth)), // decode and save it
  //     switchMap((keycloakTokenInfo: KeycloakTokenInfo) => this.initOpenId(keycloakTokenInfo)), // Init keycloak
  //     switchMap((auth) => this.getUserInStorage(auth)),
  //     catchError((err) => {
  //       console.error('err', err);
  //       return of(undefined);
  //     }),
  //     finalize(() => this.isLoadingSubject.next(false))
  //   );
  // }

  login(email: string, password: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.accountService.login(email, password).pipe(
      tap((keycloakTokenInfo: KeycloakTokenInfo) => this.initOpenId(keycloakTokenInfo, true)), //  save token
      tap((keycloakTokenInfo: KeycloakTokenInfo) => this.setAuthFromLocalStorage(keycloakTokenInfo)), // decode and save it
      switchMap((keycloakTokenInfo: KeycloakTokenInfo) => this.getUserInStorage()),
      switchMap((decodeInfo: KeycloakTokenDecodeInfo) => this.getMeInfo()),
      switchMap((iohUser: IohUser) => fromPromise(this.conceptsDbService.cacheDB(iohUser))),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.storageService.deleteAll();
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    console.log(auth);
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.accountService.getUserByToken(auth.accessToken).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // getUserInKeyCloak(keycloakTokenInfo: KeycloakTokenInfo): Observable<any> {
  //   console.log(keycloakTokenInfo);
  //   const identity: KeycloakTokenDecodeInfo = this.storageService.getIdentity();
  //   const token = this.storageService.getToken();
  //   if (!(identity && token)) {
  //     return of(undefined);
  //   }
  //
  //   this.isLoadingSubject.next(true);
  //   const instance = this.openIdService.getKeycloakInstance();
  //   const loadUserInfo$ = fromPromise(instance.loadUserInfo());
  //   return loadUserInfo$
  //     .pipe(
  //       map(() => {
  //         const userInstance = instance.userInfo;
  //         console.log(userInstance);
  //         return userInstance;
  //       }),
  //       finalize(() => this.isLoadingSubject.next(false))
  //     );
  // }

  getUserInStorage(): Observable<KeycloakTokenDecodeInfo> {
    const identity: KeycloakTokenDecodeInfo = this.storageService.getIdentity();
    const token = this.storageService.getToken();
    if (!(identity && token)) {
      return of(undefined);
    }

    const currentUser =  new UserModel();
    const auth = {
      accessToken: this.storageService.getToken(),
      refreshToken: this.storageService.getRefreshToken(),
      expiresIn: new Date(identity.exp * 1000),
    };
    currentUser.setUserFromKeycloak(identity, auth);
    this.currentUserSubject = new BehaviorSubject<UserModel>(currentUser);
    return of(identity);
  }

  async userIsLogin(): Promise<KeycloakTokenDecodeInfo> {
    const identity: KeycloakTokenDecodeInfo = this.storageService.getIdentity();
    const token = this.storageService.getToken();
    const refreshToken = this.storageService.getRefreshToken();
    const expiryTime = this.storageService.getExpiryTime();

    if (!(identity && token && expiryTime > Date.now())) {
      return  null;
    }

    const currentUser =  new UserModel();
    const auth = {
      accessToken: token,
      refreshToken,
      expiresIn: new Date(expiryTime),
    };
    currentUser.setUserFromKeycloak(identity, auth);
    this.currentUserSubject = new BehaviorSubject<UserModel>(currentUser);

    await this.getMeInfo().toPromise();
    this.autoRefreshToken();

    return identity;
  }

  getMeInfo(): Observable<IohUser> {
    return this.userService.getMe()
      .pipe(tap(users => {
        if (Array.isArray(users) && users.length > 0) {
          const user = users[0];
          this.storageService.saveMe(user);
        }
      }));
  }

  getLocalMe(): IohUser {
    return this.storageService.getMe();
  }

  // need create new user then login
  // registration(user: UserModel): Observable<any> {
  registration(user: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.accountService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.accountService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(keycloakTokenInfo: KeycloakTokenInfo): boolean {
    if (keycloakTokenInfo && keycloakTokenInfo.accessToken) {
      const decode = this.decodeToken(keycloakTokenInfo);
      this.storageService.saveIdentity(decode);
      return true;
    }
    return false;
  }

  private decodeToken(keycloakTokenInfo: KeycloakTokenInfo): KeycloakTokenDecodeInfo {
    const accessToken = keycloakTokenInfo.accessToken;
    return KeycloakTokenDecodeInfo.fromJson(JSON.stringify(jwt_decode(accessToken)));
  }

  private initOpenId(keycloakTokenInfo: KeycloakTokenInfo, callRefresh: boolean = true): Observable<boolean> {
    const decodeInfo = this.decodeToken(keycloakTokenInfo);
    const tokenId = decodeInfo.jti;
    this.storageService.saveIdToken(tokenId);
    this.storageService.saveToken(keycloakTokenInfo.accessToken);
    this.storageService.saveRefreshToken(keycloakTokenInfo.refreshToken);
    this.storageService.saveExpiryTime(keycloakTokenInfo.expiresIn * 1000 + Date.now());
    this.storageService.saveExpiryIn(keycloakTokenInfo.expiresIn * 1000);

    if (callRefresh) {
      this.autoRefreshToken();
    }

    return of(true);
    // todo
    // return this.openIdService.initializeKeycloak();
  }

  private getKeycloakInstance(auth: boolean): Observable<Keycloak.KeycloakInstance> {
    return of(this.openIdService.getKeycloakInstance());
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      return JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  autoRefreshToken(): void {
    const expiryIn = (this.storageService.getExpiryIn() || 3600000) - 5000;
    setInterval(() => {
      const openIdConfig = environment.openIdConfig;
      const refreshToken = this.storageService.getRefreshToken();
      const sb = this.accountService.refreshToken(refreshToken, openIdConfig.refreshGrantType, openIdConfig.refreshClientID)
        .subscribe(
          (keycloakTokenInfo: KeycloakTokenInfo) => this.initOpenId(keycloakTokenInfo, false),
          error => console.log(error)
        );
      this.unsubscribe.push(sb);

    }, expiryIn);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
