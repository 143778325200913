export const ApiPath = {
  geography: 'geography',
  concept: {
    concept: 'concept',
    gender: 'concept/gender',
    role: 'concept/role',
    providerRole: 'concept/provider-role',
    paymentObject: 'concept/payment-object',
    icd: 'concept/icd',
  },
  adminUnitConcept: {
    adminUnitConcept: 'admin/unit-concept'
  },
  adminMethodConcept: {
    adminMethodConcept: 'admin/method-concept'
  },
  adminConceptMeasurement: {
    admin: 'admin',
    conceptMeasurement: 'admin/measurement-concept',
    descendant: (conceptMeasurementId: number | string) => `admin/measurement-concept/${conceptMeasurementId}/descendant`
  },
  adminCareSite: {
    adminCareSite: 'admin/care-site'
  },
  careSite: 'care-site',

  careSiteMeasurementConcept: {
    careSiteMeasurementConcept: (careSiteId: number) => `care-site/${careSiteId}/measurement-concept`,
    descendant: (careSiteId: number, conceptMeasurementId: number) =>
      `care-site/${careSiteId}/measurement-concept/${conceptMeasurementId}/descendant`,
    descendantInfo: (careSiteId: number, descendantId: number, conceptId: number) =>
      `care-site/${careSiteId}/descendant/${descendantId}/measurement-concept/${conceptId}/descendant`
  },
  descendantCareSite: {
    descendantCareSiteInfo: (careSiteId: number, descendantId: number, conceptId: number) =>
      `care-site/${careSiteId}/descendant/${descendantId}/measurement-concept/${conceptId}/descendant`
  },
  measurementPackageConcept: {
    measurementPackageConcept: (careSiteId: number) => `care-site/${careSiteId}/measurement-package-concept`,
    addMeasurementConceptIntoPackage: (careSiteId: number, measurementPackageConceptId: number) =>
      `​care-site​/${careSiteId}/measurement-package-concept​/${measurementPackageConceptId}/measurement-concept`,
    updateMeasurementPackageConceptPrice: (careSiteId: number, measurementPriceId: number) =>
      `​​care-site​/${careSiteId}/measurement-package-concept​/price​/${measurementPriceId}`,
  },
  user: {
    user: 'user',
    me: 'user/me',
  },
  account: {
    login: 'auth/login',
    register: 'register',
    users: 'users'
  },
  provider: {
    providerByCareSite: (careSiteId: number) => `care-site/${careSiteId}/provider`
  },
  patient: {
    patient: 'patient',
    createPatient: (careSiteID: number) => `care-site/${careSiteID}/patient`
  },
  measurement: {
    measurement: 'measurement'
  },
  visitOccurrence: {
    visitOccurrence: 'visit-occurrence'
  },
  visitDetail: {
    visitDetail: 'visit-detail'
  }
};
