import {Injectable} from '@angular/core';

import * as Keycloak from 'keycloak-js';
import {KeycloakConfig} from 'keycloak-js';
import Lodash from 'lodash';
import {environment} from '../../../../environments/environment';
import {KeycloakService} from 'keycloak-angular';
import {StorageService} from '../../../ucare/services/storage/storage.service';
import {fromPromise} from 'rxjs/internal-compatibility';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpenIdService {

  constructor(
    private keycloak: KeycloakService,
    private storageService: StorageService
  ) {
  }

  // initKeycloak() {
  //     this.keycloak.init({
  //         onLoad: 'check-sso',
  //         checkLoginIframe: true,
  //         checkLoginIframeInterval: 5,
  //         token: this.storageService.getToken(),
  //         idToken: this.storageService.getIdToken(),
  //         refreshToken: this.storageService.getRefreshToken()
  //     }).then(async (auth) => {
  //         if (!auth) {
  //             return;
  //         }
  //
  //         this.storageService.saveIdentity(this.keycloak);
  //         await this.authorizationService.collectorIdentity();
  //
  //         setTimeout(() => {
  //             this.keycloak.updateToken(1)
  //                 .then((refreshed) => {
  //                     if (refreshed) {
  //                         console.log('Token refreshed' + refreshed);
  //                     } else {
  //                         console.warn('Token not refreshed, valid for '
  //                             + Math.round(this.keycloak.tokenParsed.exp + this.keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
  //                     }
  //                 }).catch(() => {
  //                 console.error('Failed to refresh token');
  //             });
  //         }, 60000);
  //
  //     }).catch(() => {
  //         console.error('Authenticated Failed');
  //     });
  // }

  // Keycloak init
  initializeKeycloak(): Observable<boolean> {
    console.log('initializeKeycloak');
    const openIdConfig = environment.openIdConfig;
    return fromPromise(
      this.keycloak.init({
        config: {
          url: openIdConfig.authUrl,
          realm: openIdConfig.realm,
          clientId: openIdConfig.clientId,
        },
        initOptions: {
          token: this.storageService.getToken(),
          idToken: this.storageService.getIdToken(),
          refreshToken: this.storageService.getRefreshToken()
        }
      })
    );
  }

  getKeycloakInstance(): Keycloak.KeycloakInstance {
    return this.keycloak.getKeycloakInstance();
  }

  /**
   * @deprecated Do not using this function in UCare
   */
  login() {
    this.keycloak.login({
      redirectUri: environment.openIdConfig.redirectUri
    });
  }

  logout() {
    this.keycloak.logout();
  }

  async isAuthenticated() {
    try {
      return await this.keycloak.isLoggedIn();
    } catch (e) {
      console.log(e);
      return false;
    }

  }
}
