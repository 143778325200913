import {Injectable} from '@angular/core';
import {ApiPath} from '../../constance/api-path';
import {Observable} from 'rxjs';
import {IohUser} from '../../models/user/ioh-user';
import {ApiService} from '../_core/api.service';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

const UserPath = ApiPath.user;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apiService: ApiService
  ) { }

  getMe(): Observable<IohUser> {
    const url = `${environment.uCareUrl}/${UserPath.me}`;
    return this.apiService.get(url)
      .pipe(
        map((response: HttpResponse<any>) => {
          const body = response.body;
          return IohUser.fromJson(JSON.stringify(body));
        })
      );
  }

  createUser(user: IohUser): Observable<IohUser> {
    const url = `${environment.uCareUrl}/${UserPath.user}`;
    return this.apiService.post(url, user)
      .pipe(
        map((response: HttpResponse<any>) => {
          const body = response.body;
          return IohUser.fromJson(JSON.stringify(body));
        })
      );
  }

  getListUser(offset: number = 0, limit: number = 10): Observable<Array<IohUser>> {
    const url = `${environment.uCareUrl}/${UserPath.user}`;
    return this.apiService.get(url)
      .pipe(
        map((response: HttpResponse<any>) => {
          const body = response.body || [];
          return body.map(item => IohUser.fromJson(JSON.stringify(item)));
        })
      );
  }
}
