import {Injectable} from '@angular/core';
import {deleteDB, openDB} from 'idb';
import {IDBPDatabase, IDBPTransaction} from 'idb/build/esm/entry';
import {DbInfo} from '../../constance/db-info';
import {BehaviorSubject, Observable} from 'rxjs';
import {GeographyService} from '../geography/geography.service';
import {ConceptService} from '../concept/concept.service';
import {IohIcdType} from '../../models/concept/ioh-icd-type';
import {StorageService} from '../storage/storage.service';
import {IohUser} from '../../models/user/ioh-user';
import {CareSiteMeasurementConceptService} from '../care-site-measurement-concept/care-site-measurement-concept.service';
import {CareSiteMeasurementPackageConceptService} from '../care-site-measurement-package-concept/care-site-measurement-package-concept.service';

@Injectable({
  providedIn: 'root'
})
export class ConceptsDbService {

  // region Obs
  private _dbIsReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public dbIsReady: Observable<boolean> = this._dbIsReady.asObservable();

  // endregion Obs


  private db: IDBPDatabase;

  constructor(
    private geographyService: GeographyService,
    private conceptService: ConceptService,
    private storageService: StorageService,
    private measurementConceptService: CareSiteMeasurementConceptService,
    private measurementPackageConceptService: CareSiteMeasurementPackageConceptService
  ) {
    this.connectDatabase(DbInfo.DB_NAME.CONCEPT, DbInfo.DB_VERSION)
      .then(
        res => {
          console.log('Connect database success');
          this.setDbIsReady(true);
        },
        error => {
          console.log('Connect database fail');
          console.log(error);
        }
      );
  }

  // region Set & Get Obs
  setDbIsReady(isReady: boolean): void {
    this._dbIsReady.next(isReady);
  }

  getDbIsReady(): boolean {
    return this._dbIsReady.getValue();
  }
  // endregion Set & Get Obs

  private async connectDatabase(name: string, version: number): Promise<any> {
    this.db = await openDB(name, version, {
      upgrade(db, oldVersion, newVersion, transaction) {
        console.log('DB is upgrade');
        console.log(db);

        // GEOGRAPHY
        createStores(db, DbInfo.STORES.GEOGRAPHY_PROVINCE, {keyPath: 'province_id', autoIncrement: true}, null);

        // CONCEPT
        createStores(db, DbInfo.STORES.CONCEPT_GENDER, {keyPath: 'concept_id', autoIncrement: true}, null);
        createStores(db, DbInfo.STORES.CONCEPT_ROLE, {keyPath: 'concept_id', autoIncrement: true}, null);
        createStores(db, DbInfo.STORES.CONCEPT_PROVIDER_ROLE, {keyPath: 'concept_id', autoIncrement: true}, null);
        createStores(db, DbInfo.STORES.CONCEPT_PAYMENT_OBJECT, {keyPath: 'concept_id', autoIncrement: true}, null);
        createStores(db, DbInfo.STORES.CONCEPT_ICD_10, {keyPath: 'concept_id', autoIncrement: true}, null);

        // ADMIN CONCEPT
        createStores(db, DbInfo.STORES.ADMIN_METHOD_CONCEPT, {keyPath: 'concept_id', autoIncrement: true}, null);
        createStores(db, DbInfo.STORES.ADMIN_UNIT_CONCEPT, {keyPath: 'concept_id', autoIncrement: true}, null);
        createStores(db, DbInfo.STORES.ADMIN_MEASUREMENT_CONCEPT, {keyPath: 'concept_id', autoIncrement: true}, null);

        // CARE SITE CONCEPT
        createStores(db, DbInfo.STORES.CARE_SITE_MEASUREMENT_CONCEPT, {keyPath: 'concept_id', autoIncrement: true}, null);
        createStores(db, DbInfo.STORES.CARE_SITE_MEASUREMENT_PACKAGE_CONCEPT, {keyPath: 'concept_id', autoIncrement: true}, null);


        // Util functions
        function createStores(dbX: IDBPDatabase,
                              nameX: string,
                              optionsX: { keyPath: string, autoIncrement: boolean },
                              indexOptionX: { name: string, keyPath }
        ): void {
            // Create a store of objects
            const storeX = dbX.createObjectStore(nameX, {...optionsX});
            // Create an index on the 'date' property of the objects.
            if (indexOptionX && indexOptionX.name && indexOptionX.name) {
              storeX.createIndex(indexOptionX.name, indexOptionX.keyPath);
          }
        }
      },
      blocked() {
        console.log('DB is blocked. Connect process!');
      },
      blocking() {
        console.log('DB is blocking');
      },
      terminated() {
        console.log('DB is terminated');
      },
    });
  }

  async cacheDB(iohUser: IohUser): Promise<any> {
    try {
      if (this.db) {
        await this.cacheGeography();
        await this.cacheSystemConcept();
        await this.cacheCareSiteConcept();
        return iohUser;
      }
      return null;
    } catch (e) {
      console.log(e);
      console.error('Cache DB Fail');
    }
  }

  addData(transaction: IDBPTransaction<any, any, any>, data: any): Promise<any> {
    return transaction.store.add(data);
  }

  private async deleteDb(name): Promise<any> {
    await deleteDB(name, {
      blocked() {
        console.log('DB is blocked. DeleteDB process!');
      },
    });
  }

  async cacheGeography(): Promise<void> {
    if (!this.db) {
      console.error('DB not ready');
      return;
    }

    const iohGeography = await this.geographyService.getPlainGeography().toPromise();
    const listProvince = iohGeography.list || [];

    {
      const tx = this.db.transaction(DbInfo.STORES.GEOGRAPHY_PROVINCE, 'readwrite');
      await Promise.all([
          ...listProvince.map(province => this.addData(tx, province)),
          tx.done
      ]);
    }
  }

  async cacheSystemConcept(): Promise<void> {
    if (!this.db) {
      console.error('DB not ready');
      return;
    }

    const genders = await this.conceptService.getPlainGender().toPromise();
    {
      const tx = this.db.transaction(DbInfo.STORES.CONCEPT_GENDER, 'readwrite');
      await Promise.all([
        ...genders.map(gender => this.addData(tx, gender)),
        tx.done
      ]);
    }

    const roles = await this.conceptService.getPlainRole().toPromise();
    {
      const tx = this.db.transaction(DbInfo.STORES.CONCEPT_ROLE, 'readwrite');
      await Promise.all([
        ...roles.map(role => this.addData(tx, role)),
        tx.done
      ]);
    }

    const providerRoles = await this.conceptService.getPlainRoleProvider().toPromise();
    {
      const tx = this.db.transaction(DbInfo.STORES.CONCEPT_PROVIDER_ROLE, 'readwrite');
      await Promise.all([
        ...providerRoles.map(role => this.addData(tx, role)),
        tx.done
      ]);
    }

    const paymentObjects = await this.conceptService.getPlainPaymentObject().toPromise();
    {
      const tx = this.db.transaction(DbInfo.STORES.CONCEPT_PAYMENT_OBJECT, 'readwrite');
      await Promise.all([
        ...paymentObjects.map(role => this.addData(tx, role)),
        tx.done
      ]);
    }

    const icd10s = await this.conceptService.getPlainICD(IohIcdType.ICD_10).toPromise();
    {
      const tx = this.db.transaction(DbInfo.STORES.CONCEPT_ICD_10, 'readwrite');
      await Promise.all([
        ...icd10s.map(role => this.addData(tx, role)),
        tx.done
      ]);
    }

  }

  async cacheCareSiteConcept(): Promise<void> {
    if (!this.db) {
      console.error('DB not ready');
      return;
    }

    const me = this.storageService.getMe();
    const careSiteId = this.getCareSiteId(me);

    const measurementConcept = await this.measurementConceptService.getConceptMeasurement(careSiteId).toPromise();
    {
      const tx = this.db.transaction(DbInfo.STORES.CARE_SITE_MEASUREMENT_CONCEPT, 'readwrite');
      await Promise.all([
        this.addData(tx, measurementConcept),
        tx.done
      ]);
    }

    const measurementPackageConcepts = await this.measurementPackageConceptService.getMeasurementPackageConcept(careSiteId).toPromise();
    {
      const tx = this.db.transaction(DbInfo.STORES.CARE_SITE_MEASUREMENT_PACKAGE_CONCEPT, 'readwrite');
      await Promise.all([
        this.addData(tx, measurementPackageConcepts),
        tx.done
      ]);
    }
  }

  getCareSiteId(me: IohUser): number {
    if (me.provider) {
      return me.provider.careSiteId || -1;
    }

    const careSites = me.careSites;
    if (Array.isArray(careSites) && careSites.length > 0) {
      const careSite = careSites[0];
      return careSite.careSiteId;
    }

    return -1;
  }
}
