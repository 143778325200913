import {Expose, Type} from 'class-transformer';
import {IohDistrictDescendants} from './ioh-district-descendants';

export class IohWardDescendant {
  @Expose({ name: 'ward_id' })
  wardId: number;

  @Expose({ name: 'ward_name' })
  wardName: string;

  @Expose({ name: 'district' })
  @Type(() => IohDistrictDescendants)
  district: IohDistrictDescendants;
}
