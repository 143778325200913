import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ApiPath} from '../../constance/api-path';
import {Observable, of} from 'rxjs';
import {ApiService} from '../_core/api.service';
import {KeycloakAccountRegister} from '../../models/account/keycloak-account-register';
import {map} from 'rxjs/operators';
import {UserModel} from '../../../modules/auth';
import {KeycloakTokenInfo} from '../../models/account/keycloak-token-info';
import {HttpResponse} from '@angular/common/http';

const ACCOUNT_PATH = `${environment.accountUrl}`;
const OPEN_ID_PATH = environment.openIdConfig;

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private apiService: ApiService
  ) { }

  login(username: string, password: string): Observable<KeycloakTokenInfo> {
    const url = `${ACCOUNT_PATH}/${ApiPath.account.login}`;
    const loginData = {username, password};
    return this.apiService.post(url, loginData)
      .pipe(
        map((httpResponse: HttpResponse<any>) => {
          const body = httpResponse.body;
          delete body['not-before-policy'];
          return KeycloakTokenInfo.fromJson(JSON.stringify(body));
        })
      );
  }

  refreshToken(refreshToken: string, grantType: string, clientId: string): Observable<KeycloakTokenInfo> {
    const url = `${OPEN_ID_PATH.refreshUrl}`;

    const data = new URLSearchParams();
    data.append('refresh_token', refreshToken);
    data.append('grant_type', grantType);
    data.append('client_id', clientId);

    return this.apiService.postUrlEncoded(url, data.toString())
      .pipe(
        map((httpResponse: HttpResponse<any>) => {
          const body = httpResponse.body;
          delete body['not-before-policy'];
          return KeycloakTokenInfo.fromJson(JSON.stringify(body));
        })
      );
  }


  createUser(registerData: KeycloakAccountRegister): Observable<any> {
    const url = `${ACCOUNT_PATH}/${ApiPath.account.users}`;
    return this.apiService.post(url, registerData);
  }

  forgotPassword(email: string): Observable<boolean> {
    return of(null);
  }

  getUserByToken(token: string): Observable<UserModel> {
    return of(null);
  }
}
