import {classToPlain, deserialize, Expose, serialize, Type} from 'class-transformer';
import {IohWard} from './ioh-ward';
import {IohDistrict} from './ioh-district';

export class IohProvince {
  @Expose({name: 'province_id'})
  provinceId: number;

  @Expose({name: 'province_name'})
  provinceName: string;

  @Type(() => IohDistrict)
  districts: IohDistrict[];

  static fromJson(iohDistrictDto: any): IohDistrict {
    return deserialize(IohDistrict, iohDistrictDto);
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
