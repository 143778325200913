import {Expose, Type} from 'class-transformer';
import {IohProvinceDescendant} from './ioh-province-descendant';

export class IohDistrictDescendants {
  @Expose({ name: 'district_id' })
  districtId: number;

  @Expose({ name: 'district_name' })
  districtName: string;

  @Type(() => IohProvinceDescendant)
  province: IohProvinceDescendant;
}
