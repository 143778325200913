import {classToPlain, deserialize, Expose, serialize, Type} from 'class-transformer';
import {IohWard} from './ioh-ward';

export class IohDistrict {
  @Expose({ name: 'district_id' })
  districtId: number;

  @Expose({ name: 'district_name' })
  districtName: string;

  @Expose({ name: 'province_id' })
  provinceId: number;

  @Type(() => IohWard)
  wards: IohWard[];

  static fromJson(iohDistrictDto: any): IohDistrict {
    return deserialize(IohDistrict, iohDistrictDto);
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
