import {classToPlain, deserialize, Expose, serialize, Type} from 'class-transformer';

export class KeycloakTokenDecodeInfo {
  azp: number;
  email: string;
  acr: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  name: string;
  scope: string;
  sub: string;
  typ: string;

  @Expose({name: 'allowed-origins'})
  @Type(() => String)
  allowedOrigins: Array<string>;

  @Expose({name: 'email_verified'})
  emailVerified: string;

  @Expose({name: 'family_name'})
  familyName: string;

  @Expose({name: 'given_name'})
  givenName: string;

  @Expose({name: 'preferred_username'})
  preferredUsername: string;

  @Expose({name: 'session_state'})
  sessionState: string;

  static fromJson(keycloakTokenDecodeInfoDto: any): KeycloakTokenDecodeInfo {
    return deserialize(KeycloakTokenDecodeInfo, keycloakTokenDecodeInfoDto);
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
