import {Expose} from 'class-transformer';

export class IohCaresSiteMeasurementConceptPrice {
  @Expose({name: 'measurement_price_id'})
  measurementPriceId: number;

  @Expose({name: 'price_value'})
  priceValue: number;

  @Expose({name: 'commission_rate'})
  commissionRate: number;

  @Expose({name: 'commission_value'})
  commissionValue?: number;
}
