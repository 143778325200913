import {classToPlain, deserialize, Exclude, Expose, serialize, Type} from 'class-transformer';
import {IohCaresSiteMeasurementConceptAncestor} from './ioh-cares-site-measurement-concept-ancestor';
import {IohCaresSiteMeasurementConceptPrice} from './ioh-cares-site-measurement-concept-price';
import {UiModel} from '../_ui-helper/ui-model';
import {IohCaresSiteMeasurementConceptInfo} from './ioh-cares-site-measurement-concept-info';

export class IohCareSiteMeasurementConcept extends UiModel{
  @Expose({name: 'concept_id'})
  conceptId: number;

  @Expose({name: 'concept_name'})
  conceptName: string;

  @Expose({name: 'concept_code'})
  conceptCode: string;

  @Expose({name: 'price'})
  @Type(() => IohCaresSiteMeasurementConceptPrice)
  price?: IohCaresSiteMeasurementConceptPrice;

  @Expose({name: 'info'})
  @Type(() => IohCaresSiteMeasurementConceptInfo)
  info?: IohCaresSiteMeasurementConceptInfo;

  @Expose({name: 'descendants'})
  @Type(() => IohCareSiteMeasurementConcept)
  descendants?: IohCareSiteMeasurementConcept[];

  @Expose({name: 'ancestors'})
  @Exclude({ toPlainOnly: true })
  @Type(() => IohCaresSiteMeasurementConceptAncestor)
  ancestors?: IohCaresSiteMeasurementConceptAncestor[];

  static fromJson(iohConceptDto: any): IohCareSiteMeasurementConcept {
    return deserialize(IohCareSiteMeasurementConcept, iohConceptDto);
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
