import {classToPlain, deserialize, Exclude, Expose, Type, serialize} from 'class-transformer';
import {IohCareSite} from '../care-site/ioh-care-site';
import {UiModel} from '../_ui-helper/ui-model';
import {IohPerson} from '../person/ioh-person';
import {IohProvider} from '../provider/ioh-provider';

export class IohUser extends UiModel{
  @Expose({name: 'user_id'})
  userId: number;

  @Expose({name: 'user_uuid'})
  userUuid: string;

  @Expose({name: 'is_admin'})
  isAdmin: boolean;

  @Expose({name: 'person_id'})
  personId?: number;

  @Expose({name: 'provider_id'})
  providerId?: number;

  @Expose({name: 'created_at'})
  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  createdAt?: Date;

  @Expose({name: 'updated_at'})
  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  updatedAt?: Date;

  @Expose({name: 'care_sites'})
  @Type(() => IohCareSite)
  careSites: IohCareSite[];

  @Expose({name: 'person'})
  @Type(() => IohPerson)
  person?: IohPerson;

  @Expose({name: 'provider'})
  @Type(() => IohProvider)
  provider?: IohProvider;


  static fromJson(userDto: any): IohUser {
    return deserialize(IohUser, userDto);
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
