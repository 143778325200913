export const StorageInfo = {
  EXPIRY_TIME : 'expiryTime',
  EXPIRY_IN : 'expiryIn',
  TOKEN : 'token',
  ID_TOKEN : 'idToken',
  REFRESH_TOKEN : 'refreshToken',
  IDENTITY : 'identity',
  COLLECTOR : 'collector',
  MATCHING_USER: 'matchingUser',
  MATCHING_CODE: 'matchingCode',
  SYSTEM_PROJECT_SELECTED: 'systemProjectSelected',
  SYSTEM_PUG_SELECTED: 'systemPugSelected',
  LANGUAGE: 'language',
  PROJECT_COUNTER: 'projectCounter',
  ME: 'me',
};
