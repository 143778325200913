import {classToPlain, deserialize, Expose, serialize} from 'class-transformer';

export class IohAdminUnitConcept {
  @Expose({name: 'concept_id'})
  conceptId: string;

  @Expose({name: 'concept_name'})
  conceptName: string;

  @Expose({name: 'concept_code'})
  conceptCode: string;

  static fromJson(iohAdminUnitConcept: any): IohAdminUnitConcept {
    return deserialize(IohAdminUnitConcept, iohAdminUnitConcept);
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
