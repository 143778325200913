import {deserialize, Expose, serialize, Type} from 'class-transformer';

export class IohPerson {
  @Expose({ name: 'person_id' })
  personId: number;

  @Expose({ name: 'person_local_code' })
  personLocalCode: string;

  @Expose({ name: 'gender_concept_code' })
  genderConceptCode: string;

  @Expose({ name: 'year_of_birth' })
  yearOfBirth: number;

  @Expose({ name: 'month_of_birth' })
  monthOfBirth: number;

  @Expose({ name: 'day_of_birth' })
  dayOfBirth: number;

  @Expose({ name: 'birth_datetime' })
  @Type(() => Date)
  birthDatetime: Date;

  @Expose({ name: 'death_datetime' })
  @Type(() => Date)
  deathDatetime: Date;

  @Expose({ name: 'race_concept_code' })
  raceConceptCode: string;

  @Expose({ name: 'ethnicity_concept_code' })
  ethnicityConceptCode: string;

  @Expose({ name: 'location_id' })
  locationDd: number;

  @Expose({ name: 'provider_id' })
  providerId: number;

  @Expose({ name: 'care_site_id' })
  careSiteId: number;

  static fromJson(iohPersonDto: any): IohPerson {
    return deserialize(IohPerson, iohPersonDto);
  }

  toJson(): any {
    return serialize(this);
  }
}
