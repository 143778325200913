import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {IohGeography} from '../../models/geography/ioh-geography';
import {environment} from '../../../../environments/environment';
import {ApiPath} from '../../constance/api-path';
import {ApiService} from '../_core/api.service';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeographyService {

  constructor(
    private apiService: ApiService
  ) { }

  getGeography(): Observable<IohGeography> {
    const url = `${environment.uCareUrl}/${ApiPath.geography}`;
    return this.apiService.get(url)
      .pipe(
        map((response: HttpResponse<any>) => {
          const body = response.body;
          return IohGeography.fromJson(JSON.stringify(body));
        })
      );
  }

  getPlainGeography(): Observable<any> {
    const url = `${environment.uCareUrl}/${ApiPath.geography}`;
    return this.apiService.get(url)
      .pipe(
        map((response: HttpResponse<any>) => response.body)
      );
  }
}
