import {classToPlain, deserialize, Expose, serialize} from 'class-transformer';

export class KeycloakTokenInfo {
  @Expose({name: 'access_token'})
  accessToken: string;

  @Expose({name: 'expires_in'})
  expiresIn: number;

  @Expose({name: 'not-before-policy'})
  notBeforePolicy: number;

  @Expose({name: 'refresh_expires_in'})
  refreshExpiresIn: number;

  @Expose({name: 'refresh_token'})
  refreshToken: string;

  @Expose({name: 'token_type'})
  tokenType: string;

  @Expose({name: 'session_state'})
  sessionState: string;

  @Expose({name: 'scope'})
  scope: string;

  static fromJson(loginTokenInfoDto: any): KeycloakTokenInfo {
    return deserialize(KeycloakTokenInfo, loginTokenInfoDto);
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
