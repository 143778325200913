import {classToPlain, deserialize, Exclude, Expose, serialize, Type} from 'class-transformer';
import {IohChildrenCareSiteMeasurementConcept} from './ioh-children-care-site-measurement-concept';
import {IohCareSiteMeasurementConcept} from '../care-site-measurement-concept/ioh-care-site-measurement-concept';
import {UiModel} from '../_ui-helper/ui-model';

export class IohCareSiteMeasurementPackageConcept extends UiModel{

  @Expose({ name: 'concept_id' })
  conceptId: string;

  @Expose({ name: 'concept_code' })
  conceptCode: string;

  @Expose({ name: 'concept_name' })
  conceptName: string;

  @Expose({ name: 'measurement_concepts' })
  @Type(() => IohCareSiteMeasurementConcept)
  measurementConcepts: IohCareSiteMeasurementConcept[];

  @Exclude()
  childrenPackage: IohCareSiteMeasurementPackageConcept[] = [];

  static fromJson(dto: any): IohCareSiteMeasurementPackageConcept {
    return deserialize(IohCareSiteMeasurementPackageConcept, dto);
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
