import {Injectable} from '@angular/core';
import {ApiService} from '../_core/api.service';
import {Observable} from 'rxjs';
import {IohCareSiteMeasurementConcept} from '../../models/care-site-measurement-concept/ioh-care-site-measurement-concept';
import {environment} from '../../../../environments/environment';
import {ApiPath} from '../../constance/api-path';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';

const ConceptMeasurementPath = ApiPath.careSiteMeasurementConcept;



@Injectable({
  providedIn: 'root'
})
export class CareSiteMeasurementConceptService {

  constructor(
    private apiService: ApiService
  ) { }

  getConceptMeasurement(careSiteId: number): Observable<IohCareSiteMeasurementConcept> {
    const url = `${environment.uCareUrl}/${ConceptMeasurementPath.careSiteMeasurementConcept(careSiteId)}`;
    return this.apiService.get(url)
      .pipe(
        map((response: HttpResponse<any>) => {
          const body = response.body;
          return IohCareSiteMeasurementConcept.fromJson(JSON.stringify(body));
        })
      );
  }

  createConceptMeasurement(careSiteId: number, measurements: Array<IohCareSiteMeasurementConcept>): Observable<IohCareSiteMeasurementConcept> {
    const url = `${environment.uCareUrl}/${ConceptMeasurementPath.careSiteMeasurementConcept(careSiteId)}`;
    return this.apiService.post(url, measurements)
      .pipe(
        map((response: HttpResponse<any>) => {
          const body = response.body;
          return IohCareSiteMeasurementConcept.fromJson(JSON.stringify(body));
        })
      );
  }

  updateMeasurement(careSiteId: number,
                    measurementId: number,
                    measurement: IohCareSiteMeasurementConcept
  ): Observable<boolean> {
    const url = `${environment.uCareUrl}/${ConceptMeasurementPath.careSiteMeasurementConcept(careSiteId)}/${measurementId}`;
    return this.apiService
      .put(url, measurement)
      .pipe(map((response: HttpResponse<any>) => true));
  }

  insertLevel3Measurement(careSiteId: number,
                          measurementLevel2Id: number,
                          measurements: Array<IohCareSiteMeasurementConcept>
  ): Observable<any> {
    const url = `${environment.uCareUrl}/${ConceptMeasurementPath.descendant(careSiteId, measurementLevel2Id)}`;
    return this.apiService.post(url, measurements)
      .pipe(map((response: HttpResponse<any>) => true));
  }
}
