import {Injectable} from '@angular/core';
import {ApiPath} from '../../constance/api-path';
import {Observable} from 'rxjs';
import {IohGender} from '../../models/concept/ioh-gender';
import {ApiService} from '../_core/api.service';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {IohRole} from '../../models/concept/ioh-role';
import {IohPaymentObject} from '../../models/concept/ioh-payment-object';
import {IohIcdType} from '../../models/concept/ioh-icd-type';
import {IohIcd} from '../../models/concept/ioh-icd';

const ConceptPath = ApiPath.concept;

@Injectable({
  providedIn: 'root'
})
export class ConceptService {

  constructor(
    private apiService: ApiService
  ) {
  }

  getGender(): Observable<IohGender[]> {
    const url = `${environment.uCareUrl}/${ConceptPath.gender}`;
    return this.apiService.get(url)
      .pipe(
        map((res: HttpResponse<any>) => {
          const body = Array.isArray(res.body) ? res.body : [];
          return body.map(item => IohGender.fromJson(JSON.stringify(item)));
        })
      );
  }

  getPlainGender(): Observable<any[]> {
    const url = `${environment.uCareUrl}/${ConceptPath.gender}`;
    return this.apiService.get(url)
      .pipe(
        map((res: HttpResponse<any>) => Array.isArray(res.body) ? res.body : [])
      );
  }

  getRole(): Observable<IohRole[]> {
    const url = `${environment.uCareUrl}/${ConceptPath.role}`;
    return this.apiService.get(url)
      .pipe(
        map((res: HttpResponse<any>) => {
          const body = Array.isArray(res.body) ? res.body : [];
          return body.map(item => IohRole.fromJson(JSON.stringify(item)));
        })
      );
  }

  getPlainRole(): Observable<any[]> {
    const url = `${environment.uCareUrl}/${ConceptPath.role}`;
    return this.apiService.get(url)
      .pipe(
        map((res: HttpResponse<any>) => Array.isArray(res.body) ? res.body : [])
      );
  }

  getRoleProvider(): Observable<IohRole[]> {
    const url = `${environment.uCareUrl}/${ConceptPath.providerRole}`;
    return this.apiService.get(url)
      .pipe(
        map((res: HttpResponse<any>) => {
          const body = Array.isArray(res.body) ? res.body : [];
          return body.map(item => IohRole.fromJson(JSON.stringify(item)));
        })
      );
  }

  getPlainRoleProvider(): Observable<any[]> {
    const url = `${environment.uCareUrl}/${ConceptPath.providerRole}`;
    return this.apiService.get(url)
      .pipe(
        map((res: HttpResponse<any>) => Array.isArray(res.body) ? res.body : [])
      );
  }

  getPaymentObject(): Observable<IohPaymentObject[]> {
    const url = `${environment.uCareUrl}/${ConceptPath.paymentObject}`;
    return this.apiService.get(url)
      .pipe(
        map((res: HttpResponse<any>) => {
          const body = Array.isArray(res.body) ? res.body : [];
          return body.map(item => IohPaymentObject.fromJson(JSON.stringify(item)));
        })
      );
  }

  getPlainPaymentObject(): Observable<any[]> {
    const url = `${environment.uCareUrl}/${ConceptPath.paymentObject}`;
    return this.apiService.get(url)
      .pipe(
        map((res: HttpResponse<any>) => Array.isArray(res.body) ? res.body : [])
      );
  }

  getICD(icdType: IohIcdType): Observable<IohIcd[]> {
    const url = `${environment.uCareUrl}/${ConceptPath.icd}?icd_type=${icdType}`;
    return this.apiService.get(url)
      .pipe(
        map((res: HttpResponse<any>) => {
          const body = Array.isArray(res.body) ? res.body : [];
          return body.map(item => IohIcd.fromJson(JSON.stringify(item)));
        })
      );
  }

  getPlainICD(icdType: IohIcdType): Observable<any[]> {
    const url = `${environment.uCareUrl}/${ConceptPath.icd}?icd_type=${icdType}`;
    return this.apiService.get(url)
      .pipe(
        map((res: HttpResponse<any>) => Array.isArray(res.body) ? res.body : [])
      );
  }
}
