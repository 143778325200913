import {classToPlain, deserialize, Expose, serialize} from 'class-transformer';

export class IohWard {
  @Expose({ name: 'ward_id' })
  wardId: number;

  @Expose({ name: 'ward_name' })
  wardName: string;

  @Expose({ name: 'district_id' })
  districtId: number;

  static fromJson(iohWardDto: any): IohWard {
    return deserialize(IohWard, iohWardDto);
  }

  serialize(): string {
    return serialize(this);
  }

  toPlain(): any {
    return classToPlain(this);
  }
}
